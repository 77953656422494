import React, { useState, useEffect } from 'react';

const CouponChecker = () => {
  const [couponCode, setCouponCode] = useState('');
  const [email, setEmail] = useState('');
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [giftOptions, setGiftOptions] = useState([]);
  const [selectedGift, setSelectedGift] = useState('');
  const [isValidGift, setIsValidGift] = useState(false);
  const [isRedempted, setIsRedempted] = useState(false);
  const [redemptedCoupon, setRedemptedCoupon] = useState({});
  const [activeSlide, SetActiveSlide] = useState(0);
  const [error, setError] = useState('');
  
  

  const errors = {
    net_error : "C'è stato un errore nel controllo del coupon, si prega di riprovare.",
    malformed_code: "Il codice inserito non è valido",
    response_error: "C'è stato un errore nel controllo del coupon, si prega di riprovare.",
    db_error: "C'è stato un errore nel controllo del coupon, si prega di riprovare.",
    no_results: "Il codice inseito non è valido oppure è già stato utilizzato"
  }

  useEffect(() => {
    setIsValidCoupon(false);
    setIsValidGift(false);
    setIsRedempted(false);
  }, [couponCode])



    
  useEffect(() => {
    setIsValidGift(false);
    setIsRedempted(false);
    setSelectedGift('');
  }, [giftOptions])



  const handleError = (eCode) => {
    if (eCode) {
      setError(errors[eCode]);
      console.error(eCode);
    } else  {
      setError("");
    }
  }

  const next = () => {
    SetActiveSlide((p) => (p+1));
  }

  const prev = () => {
    SetActiveSlide((p) => (p-1));
  }
  
  const checkCoupon = async () => {
    setIsValidCoupon(false);
    if (/^[a-zA-Z0-9]{10}$/.test(couponCode)) {

      try {
        let url = "https://xmas.kalei.do/api/checkCoupon/"+couponCode;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const result = await response.json();
          if (result.success) {
            setIsValidCoupon(true);
            setGiftOptions(result.datas || []);
            next();    
          } 
          handleError(result.error_text);   
        } else {
          handleError("response_error");
        }
      } catch (error) {
        handleError("net_error");
      }
    } else {
      handleError("malformed_code");
    }
  };

  const checkGift = () => {
    if (selectedGift) {
      setIsValidGift(true);
      next();    
    } else {
      handleError("empty_gift");
    }
  };

  const parseExtraDatas = (giftId) => {
    try {
      let index = giftOptions.findIndex(e => e.gift_id === giftId);
      return Object.entries(JSON.parse(giftOptions[index].gift_extradatas));
    } catch (error) {
      return [];
    }
  };


  const redeemCoupon = async () => {
    try {
      let url = "https://xmas.kalei.do/api/redeemCoupon/"+couponCode;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "notification_email" : email, "gift_id" : selectedGift }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          setIsRedempted(true);
          setRedemptedCoupon(result.datas && result.datas[0] ? result.datas[0] : {})
          next();    
        }
        handleError(result.error_text);
      } else {
        handleError("response_error");
      };
    } catch(error) {
      handleError("net_error");
    };
  };

  return (
    <div className="fullheight">
      <div className="stepper fullheight" >
        <div className="stepper_slider fullheight" style={{marginLeft:-activeSlide*100+'%'}}>
          <div id="s1" className="step fullheight">
          {!isRedempted && (
            <div className="container fullheight">
              <div className="content">
                <label htmlFor="couponInput">Inserisci il codice che trovi nel biglietto di auguri</label>
                <input
                  type="text"
                  id="couponInput"
                  value={couponCode}
                  placeholder='xxxxxxxxxx'
                  onChange={(e) => {setCouponCode(e.target.value); handleError("");}}
                />
                <div className='error'>{error}</div>
              </div>
              <div className="buttons">
                <button className="next" onClick={checkCoupon} disabled={!(/^[a-zA-Z0-9]{10}$/.test(couponCode))}>Avanti</button>
              </div>
            </div>
          )}
          </div>
          
          <div id="s2" className="step">
            
            {isValidCoupon && !isRedempted && (
              <div className="container">
                <div className="content">
                  <select onChange={(e) => setSelectedGift(e.target.value)} value={selectedGift}>
                    <option value="">
                        Seleziona il tuo regalo
                      </option>
                    {
                    giftOptions.map((gift, index) => (
                      <option key={gift.gift_id} value={gift.gift_id}>
                        {gift.gift_title}
                      </option>
                    ))}
                  </select>
                  <div className='description'>{(selectedGift) ? giftOptions[giftOptions.findIndex(e => e.gift_id === selectedGift)].gift_description : ""}</div>
                  <div className='datas'>
                  {
                  parseExtraDatas(selectedGift).map(([data, index]) => (
                      <div key={data}>
                        <p className="key">{data}</p>
                        <p className="value">{index}</p>
                      </div>
                  ))}
                  </div>
                  
                </div>
                <div className="buttons">
                  <button className="prev" onClick={prev}>Indietro</button>
                  <button className="next" onClick={checkGift} disabled={selectedGift === ""}>Avanti</button>
                </div>
              </div>
            )}
          </div>
          <div id="s3" className="step">
          {isValidGift && !isRedempted && (
            <div className="container">
              <div className="content">
                <label htmlFor="emailInput">Inserisci la mail dove vuoi ricevere il biglietto</label>
                <input
                  type="email"
                  id="emailInput"
                  value={email}
                  placeholder='nome@dominio.xx'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className='error'>{error}</div>
              </div>
               <div className="buttons">
              <button className="prev" onClick={prev}>Indietro</button>
              <button className="next" onClick={redeemCoupon} disabled={!(/^[\w\.\-]+@([\w\-]+\.)+[\w]{2,4}$/.test(email))}>Conferma</button>
              </div>
            </div>
          )}
          </div>
          <div id="s4" className="step">
          {isRedempted && (
            <div className="container">
              <div className="content">
              <div className='thankyou'> Grazie per aver riscattato il nostro regalo </div>
              <div className='complete-intructions'>
                <p> Riceverai entro pochi giorni il biglietto per {redemptedCoupon.gift_title} all'indirizzo {redemptedCoupon.coupon_notification_email}.</p>
                <p> Contattaci per eventuali problemi o necessità all'indirizzo <a href='mailto:info@kalei.do'>info@kalei.do</a>.</p>
              </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
    


  );
};

export default CouponChecker;
